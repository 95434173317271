<template>
  <v-card class="px-5">
    <v-card-title primary-title>
      <v-row>
        <v-autocomplete
          :items="provedores"
          item-text="namelong"
          item-value="id"
          label="Proveedor"
          v-model="id_proveedor"
          return-object
          clearable
          :readonly="tipo == 'ver'"
          @change="obtenerListado()"
        >
        </v-autocomplete>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-title>
    <v-card-text
      ><v-row>
        <v-col cols="3">
          <v-text-field
            style="font-size: 20pt, color:'green'"
            label="Monto en que va ssssssssssa salir del banco"
            v-model="montoMonExt"
            readonly
            :prefix="symbol"
          ></v-text-field>
          <!-- :prefix="acronym" -->
        </v-col>
      </v-row>
      <!-- v-model="value" -->
      <v-tabs color="#A43542" slider-color="primary" fixed-tabs v-model="tab">
        <v-tab href="#ref1">STEP 1</v-tab>
        <v-tab href="#ref2" :disabled="step2">STEP 2</v-tab>
        <v-tab-item value="ref1">
          <v-row class="mt-5">
            <v-col cols="3">
              <v-text-field label="Buscar..." v-model="search"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-btn
                v-if="selected.length > 0"
                color="green darken-4"
                @click="continuar()"
                dark
                >Continuar</v-btn
              >
            </v-col>
            <v-col md="12" cols="12">
              <v-data-table
                :headers="headers"
                :items="listPagosXProveedorCxP"
                show-select
                v-model="selected"
              >
                <template v-slot:[`item.item_moneda`]="{ item }">
                  <v-autocomplete
                    :items="$store.state.itemsCoinsList"
                    v-model="id_coins"
                    item-text="acronym"
                    item-value="id"
                    label="Moneda"
                    v-on:blur="calcularTotales()"
                    :disabled="
                      selected.filter((v) => v.id == item.id).length == 0
                    "
                  ></v-autocomplete>
                </template>
                <!--  -->
                <template v-slot:[`item.tipo_cambio`]="{ item }">
                  <v-text-field
                    v-model="tipocambio"
                    type="number"
                    step="0.01"
                    :disabled="
                      selected.filter((v) => v.id == item.id).length == 0
                    "
                    v-on:blur="calcularTotales()"
                    @focus.native="calcularTotales()"
                  ></v-text-field>
                </template>

                <template v-slot:[`item.monto_pagar_ex`]="{ item }">
                  <v-text-field
                    v-model="item.monto_mon_ext"
                    type="number"
                    :prefix="symbol"
                    v-on:blur="calcularTotalesInput()"
                    @focus.native="calcularTotalesInput()"
                    :error-messages="item.errorEx"
                  ></v-text-field>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="ref2">
          <v-form ref="frmRegister">
            <v-row class="mt-5">
              <v-col md="3" cols="3">
                <v-text-field
                  v-model="nro_operacion"
                  label="N° de Operación"
                  v-on:blur="ValidaNroOp()"
                  :error-messages="msgNroOperacion"
                  id="nro_operacion"
                  :rules="[(v) => !!v || 'Datos Requerido']"
                  :success-messages="successMessages"
                ></v-text-field>
              </v-col>
              <v-col cols="3" md="3">
                <v-text-field
                  type="date"
                  v-model="date"
                  label="Fecha de Pago"
                  :rules="[(v) => !!v || 'Datos Requerido']"
                ></v-text-field>
              </v-col>
              <v-col md="6" cols="6">
                <v-file-input
                  label="Cargar Archivo"
                  v-model="payfile"
                  @change="uploadFile()"
                  :success-messages="msgfile"
                  :error-messages="errfile"
                  :rules="[(v) => !!v || 'Datos Requerido']"
                  :loading="loadingFile"
                >
                </v-file-input>
              </v-col>

              <v-col md="4" cols="4">
                <v-autocomplete
                  :items="(cuentas || []).filter((v) => v.symbol == symbol)"
                  item-text="label"
                  item-value="id"
                  label="Cuenta de Salida de Banco"
                  v-model="id_cuenta"
                  return-object
                  :readonly="tipo == 'ver'"
                  :rules="[(v) => !!v || 'Datos Requerido']"
                ></v-autocomplete>
              </v-col>
              <v-col md="4" cols="4">
                <v-text-field
                  readonly
                  v-model="id_cuenta.banco"
                  label="Banco:"
                  v-if="tipo == 'nuevo'"
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="4">
                <v-text-field
                  readonly
                  v-model="id_cuenta.moneda"
                  label="Moneda:"
                  v-if="tipo == 'nuevo'"
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="4"
                ><v-text-field
                  readonly
                  v-model="id_cuenta.cuenta"
                  label="N° Cuenta:"
                  v-if="tipo == 'nuevo'"
                ></v-text-field
              ></v-col>
              <v-col md="4" cols="4"
                ><v-text-field
                  readonly
                  v-model="id_cuenta.cci"
                  label="CCI:"
                  v-if="tipo == 'nuevo'"
                ></v-text-field
              ></v-col>
              <v-col md="4" cols="4"
                ><v-text-field
                  v-model="comentarios"
                  label="Comentarios"
                  placeholder="Comentarios......"
                ></v-text-field
              ></v-col>
              <v-col md="12" cols="12" v-if="tipo == 'nuevo'">
                <v-flex text-right>
                  <v-btn color="success" @click="_putPayForProveedor()">
                    PROCESAR PAGO
                  </v-btn>
                </v-flex>
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";

import { mapActions, mapState } from "vuex";
export default {
  props: ["tipo"],
  data() {
    return {
      successMessages: "",
      symbol: "",
      search: "",
      montoMonExt: 0,
      selected: [],
      nro_operacion: "",
      id_coins: 1,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      payfile: [],
      payPath: "",
      id_proveedor: "",
      boolFile: false,
      cuentas: [],
      id_cuenta: "",

      tab: null,
      total: 0,
      step2: true,
      name_path: "",
      url_documento: "",
      totalPagado: 0,
      tipocambio: 1,
      comentarios: "",
      loadingFile: false,
      errfile: "",
      msgfile: "",
      headers: [
        {
          text: "Factura",
          value: "nro_factura",
        },

        {
          text: "Expediente",
          value: "codigo_house",
        },
        {
          text: "Procedencia",
          value: "procedencia",
        },

        {
          text: "Monto",
          value: "monto_pagar",
        },

        {
          text: "Monto Deuda",
          value: "monto_deuda",
        },

        {
          text: "Tipo de Cambio",
          value: "tipo_cambio",
        },
        {
          text: "Moneda Pagar",
          value: "item_moneda",
        },
        {
          text: "Monto Pagar",
          value: "monto_pagar_ex",
          shortable: false,
        },
      ],
      msgNroOperacion: "",
    };
  },
  async mounted() {
    let vm = this;
    vm.$store.state.drawer = true;
    await this.cargarProveedores();
    await this.obtenerListado();
    await this.getListBanksDetailsCargar();
    await this.limpiarDatos();
    await this._getCoinsList();
    setTimeout(() => {
      this.obtenerDatos();
    }, 1000);
    vm.$store.state.drawer = false;
  },
  methods: {
    ...mapActions([
      "_getListaPagosXProveedorCxP",
      "_uploadFile",
      "getValidaNroOp",
      "cargarProveedores",
      "_getCoinsList",
    ]),
    obtenerListado() {
      this.tab = 0;
      this.$store.state.listPagosXProveedorCxP = [];
      if (this.id_proveedor) {
        this._getListaPagosXProveedorCxP(this.id_proveedor.id);
      }
    },

    async getListBanksDetailsCargar() {
      let vm = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_URL_MAIN + "getListBanksDetailsCargar",
        params: {
          id_branch: JSON.parse(localStorage.getItem("dataUser"))[0].id_branch,
        },
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
      };
      await axios(config)
        .then(function (response) {
          vm.cuentas = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    validarMonto(details) {
      if (details.max_pagar > details.monto_deuda) {
        document.getElementById(`pago${details.id}`).focus();
      } else {
        this.calcularTotal();
      }
    },
    calcularTotal() {
      let sum = 0;
      this.listPagosXProveedorCxP.map((item) => {
        if (item.pagar) {
          if (item.cktotal) {
            sum += parseFloat(item.monto_deuda);
            item.max_pagar = item.monto_deuda;
          } else {
            sum += parseFloat(item.max_pagar);
          }
        }
      });
      this.total = parseFloat(sum).toFixed(4);
    },
    calcularTotalPagado() {
      let sum = 0;
      this.listPagosXProveedorCxP.map((item) => {
        sum += parseFloat(item.monto_pagar);
      });
      this.totalPagado = parseFloat(sum).toFixed(4);
    },
    setearMonto(details) {
      if (details.pagar) {
        this.listPagosXProveedorCxP.filter(
          (v) => v.id == details.id
        )[0].cktotal = true;
      }
    },
    async uploadFile() {
      this.msgfile = "";
      this.errfile = "";
      if (this.payfile) {
        this.loadingFile = true;
        await this._uploadFile(this.payfile);
        this.loadingFile = false;
        this.$store.state.files.payPath
          ? (this.msgfile = "Archivo cargado")
          : (this.errfile = "Comuniquese con el admistrador");
      } else {
        this.errfile = "Dato Requerido";
      }
    },
    validar(registro = false) {
      let validacion = true;
      let msg = "";
      if (!this.payPath && registro) {
        validacion = false;
        msg += "Por favor, seleccione un documento para soporte de pago. ";
      }
      if (!this.id_cuenta && registro) {
        validacion = false;
        msg += "Por favor, seleccione una cuenta. ";
      }

      if (!this.listPagosXProveedorCxP.filter((v) => v.pagar).length > 0) {
        validacion = false;
        msg += "Seleccione al menos una cuenta a pagar. ";
      }

      if (
        this.listPagosXProveedorCxP.filter(
          (v) => parseFloat(v.monto_deuda) < parseFloat(v.max_pagar)
        ).length > 0
      ) {
        validacion = false;
        msg += "No puede realizar un pago mayor a la deuda. ";
      }
      if (!validacion) {
        this.$swal({
          icon: "error",
          title: "Lo sentimos",
          text: msg,
        });
      }

      return validacion;
    },
    async _putPayForProveedor() {
      if (this.$refs.frmRegister.validate() && !this.msgNroOperacion) {
        let vm = this;
        var data = {
          id_coins: vm.id_coins,
          tipocambio: vm.id_coins == 1 ? 1 : vm.tipocambio,
          details: vm.selected,
          fecha_pago: vm.date,
          nro_operacion: vm.nro_operacion,
          comentarios: vm.comentarios,
          id_proveedor: vm.id_proveedor.id,
          id_path: vm.$store.state.files.payPath,
          id_proveedor: vm.id_proveedor.id,
          id_cuenta: vm.id_cuenta.id,
        };
        var config = {
          method: "post",
          url: process.env.VUE_APP_URL_MAIN + "setPayForProveedor",

          headers: {
            "auth-token": localStorage.getItem("auth-token"),
            "Content-Type": "application/json",
          },
          data: data,
        };
        await axios(config)
          .then(function (response) {
            if (response.data.statusBol == true) {
              vm.$swal({
                icon: "success",
                title: "Aviso",
                text: response.data.data.msg,
              }).then((result) => {
                if (result.isConfirmed) {
                  vm.$router.go(-1);
                }
              });
              // vm.$router.go(-1);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    continuar() {
      this.step2 = false;
      this.tab = "ref2";
    },
    calcularTotales() {
      let monex = 0;
      if (this.selected.length > 0) {
        this.selected.forEach((element) => {
          this.id_coins = element.id_coins;
          this.symbol = this.$store.state.itemsCoinsList.filter(
            (v) => v.id == element.id_coins
          )[0].symbol;
          element.monto_mon_ext = this.tipocambio * element.monto_deuda;
          monex += parseFloat(element.monto_mon_ext);
        });
      }
      this.montoMonExt = parseFloat(monex).toFixed(2);
    },
    calcularTotalesInput() {
      let monex = 0;
      if (this.selected.length > 0) {
        this.selected.forEach((element) => {
          monex += parseFloat(element.monto_mon_ext);
        });
      }
      this.montoMonExt = parseFloat(monex).toFixed(2);
    },
    limpiarDatos() {
      this.payfile = [];
      this.payPath = "";
      this.id_proveedor = "";
      this.boolFile = false;
      this.tab = "ref1";
      this.step2 = true;
      this.total = 0;
      this.$store.state.listPagosXProveedorCxP = [];
    },
    async obtenerDatos() {
      if (this.tipo == "ver") {
        let vm = this;
        let id = vm.$route.params.id;

        var config = {
          method: "get",
          url:
            process.env.VUE_APP_URL_MAIN + `getVerPagosPorProveedor?id=` + id,

          headers: {
            "auth-token": localStorage.getItem("auth-token"),
            "Content-Type": "application/json",
          },
        };
        await axios(config)
          .then(function (response) {
            let data = response.data.data[0];
            vm.step2 = false;
            vm.id_proveedor = data.id_proveedor;
            vm.$store.state.listPagosXProveedorCxP = data.details;
            vm.id_cuenta = data.id_bank;
            vm.name_path = data.documento;
            vm.url_documento = data.link_documento;
            setTimeout(() => {
              vm.calcularTotalPagado();
            }, 200);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    async ValidaNroOp() {
      this.msgNroOperacion = "";
      this.successMessages = "";
      this.$store.state.spiner = true;
      let res = await this.getValidaNroOp(this.nro_operacion);
     
      if (!!this.$store.state.bancos.validacionBanco.estadoflag) {
        this.msgNroOperacion = this.$store.state.bancos.validacionBanco.mensaje;
        document.getElementById("nro_operacion").focus();
      }

      if (res.mensaje) {
        this.successMessages = `Existen Operaciones con Nro(s) Similiares: ${res.mensaje}`;
      }

      this.$store.state.spiner = false;
    },
  },
  computed: {
    ...mapState(["itemsProveedorList", "listPagosXProveedorCxP", "provedores"]),
  },
  watch: {
    selected() {
      this.calcularTotales();
    },
  },
};
</script>

<style></style>
